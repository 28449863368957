import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import axios from "axios";
import certificateImage from "./response-1.png";
import { FaDownload, FaLock, FaUnlock } from "react-icons/fa";
import certificateIllustration from "./certificate.png";

// React Router does not have any opinions about
// how you should parse URL query strings.
//
// If you use simple key=value query strings and
// you do not need to support IE 11, you can use
// the browser's built-in URLSearchParams API.
//
// If your query strings contain array or object
// syntax, you'll probably need to bring your own
// query parsing function.

export default function QueryParamsExample() {
  return (
    <Router>
      <QueryParamsDemo />
    </Router>
  );
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function QueryParamsDemo() {
  let query = useQuery();

  useEffect(() => {
    checkReportsExist();
    get_report_data();
  }, []);

  const ref = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [user_data, setUserData] = useState([]);
  const [report_data, setReportData] = useState();

  const checkReportsExist = async () => {
    setIsLoading(true);
    const response = await axios.get(
      "https://pincoapi.herokuapp.com/check/certificate/exists",
      {
        headers: {
          report_id: query.get("report_id"),
        },
      }
    );
    console.log(response.data);
    if (response?.data?.id == 1) {
      window.location.href = response?.data?.url;
    }
  };

  const get_report_data = async () => {
    setIsLoading(true);
    const response = await axios.get(
      "https://pincoapi.herokuapp.com/check/certificate/credits",
      {
        headers: {
          report_id: query.get("report_id"),
        },
      }
    );
    console.log(response.data.credits);
    setReportData(response.data);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
          <p className="text-gray-700 mt-4">
            Polishing your certificate, one pixel at a time...
          </p>
        </div>
      ) : (
        <>
          {report_data?.credits > 0 ? (
            <>
              <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
                <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
                  <h1 className="text-2xl md:text-3xl font-bold text-center mb-4">
                    Unlock Your Certificate {query.get("report_id")}
                  </h1>
                  <img
                    src={certificateIllustration}
                    alt="Certificate Illustration"
                    className="mx-auto mb-8"
                  />

                  <img
                    src={certificateImage}
                    alt="Certificate"
                    className="w-full rounded-lg shadow-md mb-8"
                  />
                  <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="text-center md:text-left">
                      <h2 className="text-lg md:text-xl font-bold mb-4">
                        Certificate
                      </h2>
                      <ul className="list-disc list-inside text-gray-500 pl-4 md:pl-0">
                        <li>Showcase your skills to potential employers</li>
                        <li>Add credibility to your job applications</li>
                        <li>Stand out from other job applicants</li>
                        <li>Increase your chances of getting hired</li>
                      </ul>
                    </div>
                    <button
                      onClick={() => {
                        window.location.href =
                          "https://pincoapi.herokuapp.com/certificate?report_id=" +
                          query.get("report_id");
                      }}
                      className="bg-[#8772ff] mt-5 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-[#8772ff] hover:text-white transition-colors duration-300"
                    >
                      <FaDownload className="mr-2" size={18} />
                      <span className="md:inline-block">
                        Download Certificate
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
              <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
                <h1 className="text-2xl md:text-3xl font-bold text-center mb-4">
                  Unlock Your Certificate {query.get("report_id")}
                </h1>
                <img
                  src={certificateIllustration}
                  alt="Certificate Illustration"
                  className="mx-auto mb-8"
                />
                <div className="flex items-center flex-col md:flex-row mb-8">
                  <FaLock className="text-gray-500 mr-2 md:mr-4" size={18} />
                  <p className="text-gray-500 text-sm md:text-base text-center md:text-left font-medium">
                    Your certificate is currently locked. To unlock it and make
                    it available to showcase on your resume and LinkedIn
                    profile, pay a one-time fee of ₹199.
                  </p>
                </div>
                <img
                  src={certificateImage}
                  alt="Certificate"
                  className="w-full rounded-lg shadow-md mb-8"
                />
                <div className="flex flex-col md:flex-row justify-between items-center">
                  <div className="text-center md:text-left">
                    <h2 className="text-lg md:text-xl font-bold mb-4">
                      Why Unlock Your Certificate?
                    </h2>
                    <ul className="list-disc list-inside text-gray-500 pl-4 md:pl-0">
                      <li>Showcase your skills to potential employers</li>
                      <li>Add credibility to your job applications</li>
                      <li>Stand out from other job applicants</li>
                      <li>Increase your chances of getting hired</li>
                    </ul>
                  </div>
                  <button
                    onClick={() => {
                      window.location.href =
                        "https://pincoapi.herokuapp.com/certificate/credit?report_id=" +
                        query.get("report_id") +
                        "&user_id=" +
                        report_data?.user_id;
                    }}
                    className="bg-[#8772ff] mt-5 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-[#8772ff] hover:text-white transition-colors duration-300"
                  >
                    <FaUnlock className="mr-2" size={18} />
                    <span className="md:inline-block">Unlock Now ₹199</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
